import './App.css';
import fire from "./firestore";
// import { Button } from 'rsuite';
import React from 'react';
import cookie from 'react-cookies'
import {Input, InputGroup, IconButton} from 'rsuite';
import DangerIcon from '@rsuite/icons/Danger';
import CloseIcon from '@rsuite/icons/Close';
import { doc, getDoc, setDoc} from 'firebase/firestore';
// import { motion } from "framer-motion"

// const docPath = 'Injury-Tracker/injury-data';
let docPath = '';
  
class InjuryCount extends React.Component{

  constructor(props){
    super(props);
    docPath = `Injury-Tracker/injury-data-${props.name}`;
    // console.log(props)
    let d = new Date();
    let last = cookie.load('lastInjury');
    let reason = cookie.load('lastInjuryReason');
    console.log("Setting state")
    console.log(last, reason)
    this.state = {
      // lastInjury: cookie.load('lastInjury'),
      lastInjury: last,
      currentTimeMs: d.getTime(),
      injuryReason: "No reason specified",
      // displayedInjuryReason: cookie.load('lastInjuryReason'),
      displayedInjuryReason: reason,
    }
    this.resetInjuryCounter = this.resetInjuryCounter.bind(this);
    this.setInjuryReason = this.setInjuryReason.bind(this);
  }

  resetInjuryCounter() {
    let last = new Date().getTime();
    if (this.state.injuryReason.length === 0) {
      this.setState({injuryReason: "No reason specified"});
    }
    this.setState({
      lastInjury: last,
      displayedInjuryReason: this.state.injuryReason
    })
    console.log("Reset the injury counter. Reason: " + this.state.displayedInjuryReason)
    cookie.save('lastInjury', last, { path: '/' })
    cookie.save('lastInjuryReason', this.state.injuryReason, { path: '/' })
    const document = doc(fire, docPath)
    console.log(document)
    const docData = {
      injuryReason: this.state.injuryReason,
      // Change this to the actual time. For testing this is fixed
      // lastInjury: 1641169007639
      lastInjury: last
    }
    setDoc(document, docData, {merge: true})
  }
  
  setInjuryReason(e){
    this.setState({
      injuryReason: e
    })
  }

  tick() {
    let d = new Date().getTime();
    this.setState(state => ({
      currentTimeMs: d,
    }));
  }

  componentDidMount() {
    async function printDocumentValue(that, doc){
      const foo = await getDoc(doc);
      let last = foo.data().lastInjury;
      let reason = foo.data().injuryReason;
      console.log(foo.data().lastInjury);
      console.log(foo.data().injuryReason);
      that.setState({
        lastInjury: last,
        injuryReason: reason,
        displayedInjuryReason: reason,
      })
    }
    console.log("Getting data from the database.")
    const testDoc = doc(fire, docPath)
    printDocumentValue(this, testDoc);
    this.interval = setInterval(() => this.tick(), 1000); 
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render(){ 
    // let injuryStreak = new Date(this.state.timeSinceInjury)
    let msSinceLastInjury = new Date() - this.state.lastInjury;
    let clock = new Date(this.state.currentTimeMs);
    let daysSinceLastInjury = Math.floor(msSinceLastInjury / (1000 * 60 * 60 * 24));
    let hoursSinceLastInjury = Math.floor((msSinceLastInjury / (1000 * 60 * 60)) % 24);
    let minutesSinceLastInjury = Math.floor((msSinceLastInjury / (1000 * 60)) % 60);
    let secondsSinceLastInjury = Math.floor((msSinceLastInjury / 1000) % 60);    

    const inputStyle = {
      width: 200,
      marginBottom: 10,
      marginTop: 10,
      background: "#000",
      color: "#14de1b",
    };

    const inputStyle2 = {
      background: "#000",
      color: "#14de1b"
    }

    const danger = {
      background: "#000",
      color: "#14de1b"
    };

    return(
      // <div className='display: flex, justify-content: space-between'>
      <div id="component">
        {/* <h1>Hello {this.props.name}!</h1> */}
        <h2>Current Time: {("0" + clock.getHours()).slice(-2)}:{("0" + clock.getMinutes()).slice(-2)}:{("0" + clock.getSeconds()).slice(-2)}</h2>
        <p>It has been: {daysSinceLastInjury} days, {hoursSinceLastInjury} hours, {minutesSinceLastInjury} minutes, {secondsSinceLastInjury} seconds since the last injury</p>
        <p>Last Injury: {this.state.displayedInjuryReason}</p>
        <div className='reason'>
          <InputGroup size="lg" style={inputStyle}>
            <InputGroup.Addon style={danger}>
              <DangerIcon style={danger}/>
            </InputGroup.Addon>
            <Input placeholder="Reason" onChange={this.setInjuryReason} style={inputStyle2}/>
          </InputGroup>
        </div>
        <IconButton size="lg" appearance="primary" color="green" icon={<CloseIcon />} onClick={this.resetInjuryCounter}>
        I Hurt Myself
        </IconButton>
      </div>
    )
  } 
}

export default InjuryCount;