// import logo from './logo.svg';
import logo from './doge.png'
import './App.css';
// import Click from './Click';
import InjuryCount from './InjuryCount';
import { motion } from 'framer-motion';

function App() { 
  return (
    <div className="App">
      <header className="App-header">
        <h1>Dogdroid's Injury Tracker</h1>
      </header>
      <div className="App-main">
      <InjuryCount name = "Counter"/>
        <motion.div
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          <img src={logo} className="App-logo" alt="logo" />
        </motion.div>
        <motion.a
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
          className="App-link"
          href="https://twitter.com/_Dogdroid"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dogdroid's Twitter
        </motion.a>
      </div>
      <footer className="App-footer">
          © {new Date().getFullYear()} Dogdroid
      </footer>
    </div>
  );
}

export default App;
