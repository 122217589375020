import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'
// import firebase from 'firebase/app'


const firebaseConfig = initializeApp({
  apiKey: "AIzaSyA5RsDZvAn333JgFzoXHnwS59idAYo2I6U",
  authDomain: "injurytracker-62ce2.firebaseapp.com",
  projectId: "injurytracker-62ce2",
  storageBucket: "injurytracker-62ce2.appspot.com",
  messagingSenderId: "464392319120",
  appId: "1:464392319120:web:de8bb6ffdef90d1aad2815"
});

const db = getFirestore(firebaseConfig);

export default db;